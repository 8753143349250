
import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
  const portfolioItems = [
    { id: 1, title: 'Project 1', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 2, title: 'Project 2', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 3, title: 'Project 3', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 4, title: 'Project 4', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 5, title: 'Project 5', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 6, title: 'Project 6', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 7, title: 'Project 7', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 8, title: 'Project 8', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
    { id: 9, title: 'Project 9', image: 'https://cdn.dribbble.com/userupload/14522928/file/original-830f5a33f44624abbd207ca4c52d8a47.jpg?resize=752x' },
  ];

  return (
    <div className="portfolio-container">
      {portfolioItems.map(item => (
        <div key={item.id} className="portfolio-item">
          <img src={item.image} alt={item.title} />
          <h3>{item.title}</h3>
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
